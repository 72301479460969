import FirestoreManager from '../api/firebaseManager';
import PlaidManager from '../api/plaidManager';
import createDataContext from './createDataContext';

const plaidReducer = (state, action) => {
  const {uid, ids, accounts, institutions, user_id} = action.payload;
  switch (action.type) {
    case 'removeItem':
      return {
        ...state,
        accounts: action.payload.accounts,
        institutions: action.payload.institutions,
        linking: action.payload.linking,
      };
    case 'initialFetch':
      return {
        ...state,
        ...action.payload,
      };
    case 'addAccount':
      return {
        ...state,
        accounts: action.payload.accounts,
        accountsMissingInfo: action.payload.accountsMissingInfo,
      };
    case 'setLinking':
      return {...state, linking: action.payload};
    case 'addInstitution':
      return {...state, institutions: action.payload};
    case 'getUserAccounts':
      return {
        ...state,
        all_accounts: {...state.all_accounts, ...accounts},
        user_accounts: {...state.user_accounts, [uid]: ids},
      };
    case 'getUserInstitutions':
      return {
        ...state,
        all_institutions: {...state.all_institutions, ...institutions},
        user_institutions: {...state.user_institutions, [uid]: ids},
      };
    case 'error':
      return {...state, error: action.payload};
    case 'removeUser':
      const filtered_accounts = {...state.user_accounts, [user_id]: []};
      const filtered_institutions = {...state.user_institutions, [user_id]: []};
      return {
        ...state,
        user_accounts: filtered_accounts,
        user_institutions: filtered_institutions,
      };
    case 'reset':
      return defaultValues;
    default:
      return state;
  }
};

const fetchInitialFromDB = dispatch => async uid => {
  try {
    const institutions = await FirestoreManager.getInstitutions(uid);

    const accountsMissingInfo = [];
    let all_accounts = [];
    await Promise.all(
      institutions.map(async ins => {
        const {item_id} = ins;
        const accounts = await FirestoreManager.getAccountsByItem(uid, item_id);
        all_accounts = [...all_accounts, ...accounts];

        accounts.map(item => {
          if (item.mask === null) {
            accountsMissingInfo.push(item);
          }
        });
      }),
    );

    const payload = {
      institutions,
      accountsMissingInfo,
      accounts: all_accounts,
      error: null,
    };
    dispatch({type: 'initialFetch', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const addMissingInfo =
  dispatch => async (uid, account, acctNum, accountsMissingInfo, accounts) => {
    try {
      account.mask = acctNum;
      const objIndex = accounts.findIndex(obj => obj.id === account.id);
      accounts[objIndex] = account;
      Promise.resolve(
        FirestoreManager.addPlaidAccount(
          account,
          uid,
          account.item_id,
          account.access_token,
        ),
      );
      dispatch({
        type: 'addAccount',
        payload: {
          accounts,
          accountsMissingInfo: accountsMissingInfo.filter(function (
            oldaccount,
          ) {
            return oldaccount !== account;
          }),
        },
      });
      return;
    } catch (err) {
      console.log(err);
    }
  };

// TODO: double check this logic
const addPlaidAccounts = dispatch => async (access_token, uid, item_id) => {
  try {
    var missingAccounts = [];
    const {data} = await PlaidManager.addAccount(access_token);

    if (!data) {
      await FirestoreManager.setPlaidItem(item_id, {
        accounts: 0,
      });
      return;
    }

    const {accounts} = data;

    accounts.map(account => {
      if (account.mask !== undefined) {
        missingAccounts.push(account);
      }
      FirestoreManager.addPlaidAccount(account, uid, item_id, access_token);
    });

    await FirestoreManager.setPlaidItem(item_id, {
      accounts: accounts.length || 0,
    });
    const payload = {
      accounts: await FirestoreManager.getAccountsByItem(uid),
      missingAccounts: missingAccounts,
    };
    dispatch({type: 'addAccount', payload});
  } catch (err) {
    console.log(err);
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const addPlaidInstitution = dispatch => async (item_id, item, uid) => {
  try {
    const customerID = (await FirestoreManager.getUserObject(uid)).customerID;
    item.customerID = customerID;
    await FirestoreManager.setPlaidItem(item_id, item);
    const payload = await FirestoreManager.getInstitutions(uid);

    dispatch({type: 'addInstitution', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const setLinking = dispatch => async value => {
  dispatch({type: 'setLinking', payload: value});
};

const removeItem = dispatch => async (uid, token, item_id, accounts) => {
  try {
    const response = await PlaidManager.removeItem(token);

    if (response.error_message !== undefined) {
      window.alert(response.error_message);
      return;
    }
    await FirestoreManager.removePlaidItemsAndAccounts(uid, token);
    const accounts = await FirestoreManager.getAccountsByItem(uid, item_id);
    const institutions = await FirestoreManager.getInstitutions(uid);

    const payload = {
      accounts,
      institutions,
      linking: false,
    };
    dispatch({type: 'removeItem', payload: payload});
  } catch (err) {
    dispatch({type: 'setLinking', payload: false});
  }
};

const getUserAccounts = dispatch => async uid => {
  try {
    const account_list = await FirestoreManager.getAccounts(uid);

    const accounts = {};
    const ids = account_list.map(account => {
      const {id} = account;
      accounts[id] = account;
      return id;
    });

    const payload = {ids, accounts, uid};
    dispatch({type: 'getUserAccounts', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const getUserInstitutions = dispatch => async uid => {
  try {
    const account_list = await FirestoreManager.getInstitutions(uid);

    const institutions = {};
    const ids = account_list.map(account => {
      const {access_token} = account;
      institutions[access_token] = account;
      return access_token;
    });

    const payload = {ids, institutions, uid};
    dispatch({type: 'getUserInstitutions', payload});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const removeUserInstitutions = dispatch => async (user_id, access_tokens) => {
  try {
    await Promise.all(
      access_tokens.map(async token => {
        const response = await PlaidManager.removeItem(token);

        if (response.error_message !== undefined) {
          window.alert(response.error_message);
          return;
        }
        await FirestoreManager.removePlaidItems(token);
      }),
    );
    dispatch({type: 'removeUser', payload: {user_id, access_tokens}});
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const resetPlaid = dispatch => async () => {
  try {
    dispatch({type: 'reset', payload: {}});
    return {success: true, error: null};
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const defaultValues = {
  institutions: [],
  accounts: [],
  accountsMissingInfo: [],
  user_accounts: {},
  user_institutions: {},
  all_accounts: {},
  all_institutions: {},
  linking: false,
  token: null,
  error: null,
};

export const {Provider, Context} = createDataContext(
  plaidReducer,
  {
    addPlaidInstitution,
    fetchInitialFromDB,
    removeItem,
    setLinking,
    addPlaidAccounts,
    addMissingInfo,
    getUserAccounts,
    getUserInstitutions,
    removeUserInstitutions,
    resetPlaid,
  },
  defaultValues,
);
