import FirestoreManager from '../api/firebaseManager';
import createDataContext from './createDataContext';

const authReducer = (state, action) => {
  const {uid, update, email, mapping} = action.payload;
  switch (action.type) {
    case 'default':
      return {...state, ...action.payload};
    case 'setClient':
      const ids = state.client_ids;
      if (!ids.includes(uid)) {
        ids.unshift(uid);
      }
      return {
        ...state,
        client_ids: ids,
        clients: {
          ...state.clients,
          [uid]: {...(state?.clients?.[uid] ?? {}), ...update},
        },
      };
    case 'deleteClient':
      const removed_ids = [...state.client_ids].filter(id => id !== uid);
      return {
        ...state,
        client_ids: removed_ids,
        clients: {...state.clients, [uid]: null},
      };
    case 'deleteUser':
      const removed_users = [...state.user_ids].filter(id => id !== uid);
      const removed_mappings = [...state.mapping_ids].filter(
        id => id !== email,
      );
      return {
        ...state,
        user_ids: removed_users,
        users: {...state.users, [uid]: null},
        mapping_ids: removed_mappings,
        user_mappings: {...state.user_mappings, [email]: null},
      };
    case 'addUserMapping':
      return {
        ...state,
        mapping_ids: [email, ...state.mapping_ids],
        user_mappings: {...state.user_mappings, [email]: mapping},
      };
    case 'reset':
      return defaultValues;
    default:
      return state;
  }
};

const getProfile = dispatch => async uid => {
  try {
    const profile = await FirestoreManager.getUserObject(uid);
    const {customerID} = profile;
    let client = {};
    if (customerID) {
      client = await FirestoreManager.getClient(customerID);
    }
    dispatch({type: 'default', payload: {profile, client}});
    return profile;
  } catch (err) {
    console.log(err);
  }
};

const setUserDoc = dispatch => async (uid, obj) => {
  try {
    await FirestoreManager.setUserObject(uid, obj);
    const profile = await FirestoreManager.getUserObject(uid);
    dispatch({type: 'default', payload: {profile}});
  } catch (err) {
    console.log(err);
  }
};

const getUsers = dispatch => async () => {
  try {
    const users_list = await FirestoreManager.getAllUsers();
    const users = {};

    const user_ids = users_list.map(user => {
      const {uid} = user;
      users[uid] = user;
      return uid;
    });
    dispatch({type: 'default', payload: {users, user_ids, users_loaded: true}});
    return users;
  } catch (err) {
    console.log(err);
  }
};

const getClients = dispatch => async () => {
  try {
    const clients_list = await FirestoreManager.getClients();

    const clients = {};

    const client_ids = clients_list.map(user => {
      const {id} = user;
      clients[id] = user;
      return id;
    });

    dispatch({
      type: 'default',
      payload: {clients, client_ids, clients_loaded: true},
    });
  } catch (err) {
    console.log(err);
  }
};

const getUserClientMappings = dispatch => async () => {
  try {
    const users_list = await FirestoreManager.getUserMappings();

    const user_mappings = {};

    const mapping_ids = users_list.map(user => {
      const {id} = user;
      user_mappings[id] = user;
      return id;
    });

    dispatch({
      type: 'default',
      payload: {mapping_ids, user_mappings, mappings_loaded: true},
    });
  } catch (err) {
    console.log(err);
  }
};

const updateClient = dispatch => async (uid, update) => {
  try {
    await FirestoreManager.setClient(uid, update);
    dispatch({type: 'setClient', payload: {uid, update}});
  } catch (err) {
    console.log(err);
  }
};

const deleteClient = dispatch => async uid => {
  try {
    await FirestoreManager.removeClient(uid);
    dispatch({type: 'deleteClient', payload: {uid}});
  } catch (err) {
    console.log(err);
  }
};

const addUserMapping = dispatch => async mapping => {
  try {
    const {email, name, customerID} = mapping;
    FirestoreManager.setUserMapping(email, {email, name, customerID});
    dispatch({type: 'addUserMapping', payload: {email, mapping}});
  } catch (err) {
    console.log(err);
  }
};

const deleteUser = dispatch => async (uid, email) => {
  try {
    if (uid) {
      await FirestoreManager.deleteFirebaseUser(uid);
      await FirestoreManager.removeUserObject(uid);
    }
    if (email) {
      await FirestoreManager.removeUserMapping(email);
    }
    dispatch({type: 'deleteUser', payload: {uid, email}});
  } catch (err) {
    console.log(err);
  }
};

const reset = dispatch => async () => {
  try {
    dispatch({type: 'reset', payload: {}});
    return {success: true, error: null};
  } catch (err) {
    dispatch({type: 'error', payload: 'Something went wrong'});
  }
};

const defaultValues = {
  profile: {},
  client: {},
  user_ids: [],
  users: {},
  client_ids: [],
  clients: {},
  mapping_ids: [],
  user_mappings: {},
  mappings_loaded: false,
  users_loaded: false,
  clients_loaded: false,
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    getProfile,
    setUserDoc,
    getUsers,
    getClients,
    getUserClientMappings,
    updateClient,
    deleteClient,
    deleteUser,
    addUserMapping,
    reset,
  },
  defaultValues,
);
